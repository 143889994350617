<template>
  <userTemplate>
    <div class="bg-danger font-28 pd-x-5">
      <el-row>
        <el-col
          :xs="{ span: 12, offset: 0 }"
          :sm="{ span: 8, offset: 4 }"
          :lg="{ span: 4, offset: 8 }"
        >
          <div>
            <p class="mg-y-6 text-white">{{ user.name }}</p>
          </div>
        </el-col>
        <el-col :xs="{ span: 12 }" :sm="{ span: 8 }" :lg="{ span: 4 }">
          <div>
            <p class="mg-y-6 text-white text-right">
              ข้อที่ {{ step.qId }}/{{ allExam.length }}
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row class="pd-x-5 bg-primary text-white text-center">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 8, offset: 8 }"
        class="h-100 min-height-screen-235 is-flex ai-center js-center"
      >
        <div v-if="viewDescrip">
          <h2 class="font-weight-light" style="font-size: 56px">รอเฉลย...</h2>
        </div>
        <div v-else>
          <h1 class="font-weight-normal">รอเฉลย...</h1>
          <div class="cicle-count mg-x-auto mg-t-1" style="font-size: 104px">
            {{ countTime }}
          </div>
        </div>
      </el-col>
    </el-row>
  </userTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import userTemplate from "@/template/VoteUserTemplate";
export default {
  components: {
    userTemplate
  },
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  computed: {
    allExam() {
      return this.$store.state.exam;
    }
  },
  watch: {
    step() {
      if (this.step.total == 1 && this.step.state != "waiting answer") {
        this.countdown();
      }
    }
  },
  mounted() {
    this.getState();
    if (this.step.total == 1 && this.step.state != "waiting answer") {
      this.checkRouterUser(this.step.state);
    }
    this.intervalation = setInterval(() => {
      this.getState();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  methods: {
    countdown() {
      this.viewDescrip = false;
      if (this.countTime <= 0) {
        this.checkRouterUser(this.step.state);
        return false;
      }
      setTimeout(() => {
        setTimeout(() => {
          this.countTime--;
          this.countdown();
        }, 1000);
      }, 600);
    }
  },
  data() {
    return {
      countTime: 5,
      viewDescrip: true
    };
  }
};
</script>