<template>
  <div class="themeVote">
    <header class="pd-5">
      <el-row>
        <el-col :span="20" :offset="2" class="text-center">
          <img style="max-width:215px;" src="@/assets/vote-logo.png" alt="" />
        </el-col>
      </el-row>
    </header>
    <div class="main-slot">
      <slot />
    </div>
    <div class="text-center border-top mg-t-4 pd-y-4 mg-x-1">
      <span class="color-grey"
        >© สงวนลิขสิทธิ์ พ.ศ. {{ new Date().getFullYear()+543 }}
        ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย</span
      >
    </div>
    <Cookie :pattern="pattern"/>
  </div>
</template>

<script>
import Cookie from "@/components/auth/Cookie";

export default {
  components: {
    Cookie,
  },
  props:{
    pattern:{
      type:Number,
      default:1
    }
  }
};
</script>

<style scoped>
.main-slot {
  min-height: 66vh;
}
</style>
